import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import './index.css'


Vue.config.productionTip = false;
Vue.use(VueAxios, axios);


window.axios = axios;
axios.defaults.baseURL = "https://api.hayokinsurance.com";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("jwt");
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = ''



new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
