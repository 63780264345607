<template>
  <body class="text-gray-800 antialiased">
    <nav
      class="
        top-0
        absolute
        z-50
        w-full
        flex flex-wrap
        items-center
        justify-between
        px-2
        py-3
        navbar-expand-lg
      "
    >
      <div
        class="
          container
          px-4
          mx-auto
          flex flex-wrap
          items-center
          justify-between
        "
      >
        <div
          class="
            w-full
            relative
            flex
            justify-between
            lg:w-auto lg:static lg:block lg:justify-start
          "
        >
          <a
            class="
              text-sm
              font-bold
              leading-relaxed
              inline-block
              mr-4
              py-2
              whitespace-no-wrap
              uppercase
              text-white
            "
            href="/"
          >
            <img
              src="/Hayok-logo.png"
              alt="Hayok Logo"
              width="60px"
              height="60px"
            /> </a
          ><button
            class="
              cursor-pointer
              text-xl
              leading-none
              px-3
              py-1
              border border-solid border-transparent
              rounded
              bg-transparent
              block
              lg:hidden
              outline-none
              focus:outline-none
            "
            type="button"
            @click="toggleNavbar('example-collapse-navbar')"
          >
            <i class="text-white fas fa-bars"></i>
          </button>
        </div>
        <div
          class="
            lg:flex
            flex-grow
            items-center
            lg:bg-transparent lg:shadow-none
            hidden
          "
          id="example-collapse-navbar"
        >
          <!-- <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/landing"
              >
              Docs</a
            >
          </li>
        </ul> -->

          <!-- <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fe fe-facebook text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Share</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fe fe-twitter text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Tweet</span></a
            >
          </li>
          <li class="flex items-center">
            <a
              class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
              href="#pablo"
              ><i
                class="lg:text-gray-300 text-gray-500 fe fe-instagram text-lg leading-lg "
              ></i
              ><span class="lg:hidden inline-block ml-2">Star</span></a
            >
          </li>
          <li class="flex items-center">
            <a href="/login" class="btn btn-primary" >
                <i class="fas fa-arrow-alt-circle-down"></i> Sign In
            </a>
            </li>
        </ul> -->
        </div>
      </div>
    </nav>
    <main>
      <div
        class="
          relative
          pt-16
          pb-32
          flex
          content-center
          items-center
          justify-center
        "
        style="min-height: 75vh"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="background-image: url('/images/hero-image1.jpg')"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>
        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  <!-- Health Insurance that Works. -->
                  Hayok Health Insurance
                </h1>
                <p class="mt-4 text-lg text-gray-300">
                  HIP is an all-encompassing Health Insurance Platform currently
                  deployed in multiple States in Nigeria. Health Insurance
                  Agencies & HMOs can enroll, manage enrollees and their
                  dependents, health facilities and third party administrators
                  in their organizations.
                </p>
                <p>
                  <a href="/#" class="btn btn-primary spacer">
                    <i class="fas fa-arrow-alt-circle-down"></i> Schedule a Demo
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            top-auto
            bottom-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
          "
          style="height: 70px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-300 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </div>
      <section class="pb-20 bg-gray-300 -mt-24">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-red-400
                    "
                  >
                    <i class="fe fe-activity"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Health Providers</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Join our network, and together, we'll give patients access
                    to the highest quality, safest and most effective health
                    care.
                  </p>
                </div>
              </div>
            </div>
            <div class="w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-blue-400
                    "
                  >
                    <i class="fe fe-users"></i>
                  </div>
                  <h6 class="text-xl font-semibold">Patients</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Learn about your health plan or find coverage for you and
                    your family.
                  </p>
                </div>
              </div>
            </div>
            <div class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-8
                  shadow-lg
                  rounded-lg
                "
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="
                      text-white
                      p-3
                      text-center
                      inline-flex
                      items-center
                      justify-center
                      w-12
                      h-12
                      mb-5
                      shadow-lg
                      rounded-full
                      bg-green-400
                    "
                  >
                    <i class="fe fe-briefcase"></i>
                  </div>
                  <h6 class="text-xl font-semibold">HMOs</h6>
                  <p class="mt-2 mb-4 text-gray-600">
                    Explore our savings, quality care and wellness solutions to
                    craft the perfect plan for your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap items-center mt-32">
            <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                class="
                  text-gray-600
                  p-3
                  text-center
                  inline-flex
                  items-center
                  justify-center
                  w-16
                  h-16
                  mb-6
                  shadow-lg
                  rounded-full
                  bg-gray-100
                "
              >
                <i class="fe fe-settings text-xl"></i>
              </div>
              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                What We Do
              </h3>
              <p
                class="
                  text-lg
                  font-light
                  leading-relaxed
                  mt-4
                  mb-4
                  text-gray-700
                "
              >
                HIP enables people to pay for healthcare efficiently and
                transparently through a user-friendly platform accessed via
                their mobile phone.
              </p>
              <p
                class="
                  text-lg
                  font-light
                  leading-relaxed
                  mt-0
                  mb-4
                  text-gray-700
                "
              >
                Individuals save money or pay their insurance premium to help
                cover their future healthcare needs. Healthcare providers, like
                clinics and hospitals; and payers, like private insurers, public
                health schemes and donors, are also linked in to the system.
              </p>
              <!-- <a
              href="https://www.creative-tim.com/learning-lab/tailwind-starter-kit#/presentation"
              class="font-bold text-gray-800 mt-8"
              >Check Tailwind Starter Kit!</a
            > -->
            </div>
            <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  bg-white
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-green-500
                "
              >
                <img
                  alt="..."
                  src="/images/teamwork.jpg"
                  class="w-full align-middle rounded-t-lg"
                />
                <blockquote class="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    class="absolute left-0 w-full block"
                    style="height: 95px; top: -94px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      class="text-green-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 class="text-xl font-bold text-white">
                    360 Digital Claims Managment
                  </h4>
                  <p class="text-md font-light mt-2 text-white">
                    An in-built and dynamic Claims management system. Also,
                    included is a Referral /patient transfer module. All digital
                    and instant.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="relative py-20">
        <div
          class="
            bottom-auto
            top-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            -mt-20
          "
          style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1585871746932-e133d3fedf4d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=705&q=80"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="
                    text-black
                    p-3
                    text-center
                    inline-flex
                    items-center
                    justify-center
                    w-16
                    h-16
                    mb-6
                    mt-2
                    shadow-lg
                    rounded-full
                    bg-green-500
                  "
                >
                  <i class="fe fe-paperclip text-xl text-white"></i>
                </div>
                <h3 class="text-3xl font-semibold">Our Vision</h3>
                <p class="mt-4 text-lg leading-relaxed text-gray-600">
                  We believe in access to good healthcare for everyone,
                  everywhere. At HIP, we believe the intersection of mobile
                  technology and mobile money has the potential to transform
                  healthcare by facilitating universal access to good quality
                  healthcare at lower costs.
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-black
                            bg-green-500
                            mr-3
                          "
                          ><i class="fe fe-activity text-white"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">
                          Easy access to health care
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-black
                            bg-green-500
                            mr-3
                          "
                          ><i class="fe fe-credit-card text-white"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">Convenient Payment</h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="
                            text-xs
                            font-semibold
                            inline-block
                            py-1
                            px-2
                            uppercase
                            rounded-full
                            text-black
                            bg-green-500
                            mr-3
                          "
                          ><i class="mdi mdi-toolbox-outline text-white"></i
                        ></span>
                      </div>
                      <div>
                        <h4 class="text-gray-600">Convenient services</h4>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="pt-20 pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-24">
          <div class="w-full lg:w-6/12 px-4">
            <h2 class="text-4xl font-semibold">Here are our heroes</h2>
            <p class="text-lg leading-relaxed m-4 text-gray-600">
              According to the National Oceanic and Atmospheric
              Administration, Ted, Scambos, NSIDClead scentist, puts the
              potentially record maximum.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80"
                class="shadow-lg rounded-full max-w-full mx-auto"
                style="max-width: 120px;"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Ryan Tompson</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                  Web Developer
                </p>
                <div class="mt-6">
                  <button
                    class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-twitter"></i></button
                  ><button
                    class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-facebook-f"></i></button
                  ><button
                    class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-dribbble"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80"
                class="shadow-lg rounded-full max-w-full mx-auto"
                style="max-width: 120px;"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Romina Hadid</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                  Marketing Specialist
                </p>
                <div class="mt-6">
                  <button
                    class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-google"></i></button
                  ><button
                    class="bg-blue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80"
                class="shadow-lg rounded-full max-w-full mx-auto"
                style="max-width: 120px;"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Alexa Smith</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                  UI/UX Designer
                </p>
                <div class="mt-6">
                  <button
                    class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-google"></i></button
                  ><button
                    class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-twitter"></i></button
                  ><button
                    class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-instagram"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full md:w-6/12 lg:w-3/12 lg:mb-0 mb-12 px-4">
            <div class="px-6">
              <img
                alt="..."
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1267&amp;q=80"
                class="shadow-lg rounded-full max-w-full mx-auto"
                style="max-width: 120px;"
              />
              <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">Jenna Kardi</h5>
                <p class="mt-1 text-sm text-gray-500 uppercase font-semibold">
                  Founder and CEO
                </p>
                <div class="mt-6">
                  <button
                    class="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-dribbble"></i></button
                  ><button
                    class="bg-red-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-google"></i></button
                  ><button
                    class="bg-blue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-twitter"></i></button
                  ><button
                    class="bg-gray-800 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                  >
                    <i class="fab fa-instagram"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->

      <section class="pb-20 relative block bg-gray-900">
        <div
          class="
            bottom-auto
            top-0
            left-0
            right-0
            w-full
            absolute
            pointer-events-none
            overflow-hidden
            -mt-20
          "
          style="height: 80px; transform: translateZ(0px)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-gray-900 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">Why Use Us</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                HIP is designed to support and connect all parties involved in
                Health Insurance Management: patients, healthcare providers and
                payers. Patients can easily access the platform with a basic
                mobile phone, as well as a smart phone. Healthcare providers and
                payers can access billing, claims, and dashboards with a desktop
                computer or tablet, too.
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="
                  text-gray-900
                  p-3
                  w-12
                  h-12
                  shadow-lg
                  rounded-full
                  bg-white
                  inline-flex
                  items-center
                  justify-center
                "
              >
                <i class="fe fe-airplay text-xl"></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                Fully Digital
              </h6>
              <p class="mt-2 mb-4 text-gray-500">
                No more paper-based claims or reports.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="
                  text-gray-900
                  p-3
                  w-12
                  h-12
                  shadow-lg
                  rounded-full
                  bg-white
                  inline-flex
                  items-center
                  justify-center
                "
              >
                <i class="mdi mdi-camera-timer text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">Real time</h5>
              <p class="mt-2 mb-4 text-gray-500">
                Key insight into health and payment data.
              </p>
            </div>
            <div class="w-full lg:w-3/12 px-4 text-center">
              <div
                class="
                  text-gray-900
                  p-3
                  w-12
                  h-12
                  shadow-lg
                  rounded-full
                  bg-white
                  inline-flex
                  items-center
                  justify-center
                "
              >
                <i class="mdi mdi-lightbulb-outline text-xl"></i>
              </div>
              <h5 class="text-xl mt-5 font-semibold text-white">
                Simple to Use
              </h5>
              <p class="mt-2 mb-4 text-gray-500">
                Intuitive interfaces for all user groups.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="relative block py-24 lg:pt-0 bg-gray-900">
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div class="w-full lg:w-6/12 px-4">
              <div
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-gray-300
                "
              >
                <div class="flex-auto p-5 lg:p-10">
                  <h4 class="text-2xl font-semibold">Want to work with us?</h4>
                  <p class="leading-relaxed mt-1 mb-4 text-gray-600">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div class="relative w-full mb-3 mt-8">
                    <label
                      class="
                        block
                        uppercase
                        text-gray-700 text-xs
                        font-bold
                        mb-2
                      "
                      for="full-name"
                      >Full Name</label
                    ><input
                      type="text"
                      v-model="work.fullname"
                      class="
                        px-3
                        py-3
                        placeholder-gray-400
                        text-gray-700
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:shadow-outline
                        w-full
                      "
                      placeholder="Full Name"
                      style="transition: all 0.15s ease 0s"
                    />
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-gray-700 text-xs
                        font-bold
                        mb-2
                      "
                      for="email"
                      >Email</label
                    ><input
                      type="email"
                      v-model="work.email"
                      class="
                        px-3
                        py-3
                        placeholder-gray-400
                        text-gray-700
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:shadow-outline
                        w-full
                      "
                      placeholder="Email"
                      style="transition: all 0.15s ease 0s"
                    />
                  </div>
                  <div class="relative w-full mb-3">
                    <label
                      class="
                        block
                        uppercase
                        text-gray-700 text-xs
                        font-bold
                        mb-2
                      "
                      for="message"
                      >Message</label
                    ><textarea
                      rows="4"
                      v-model="work.message"
                      cols="80"
                      class="
                        px-3
                        py-3
                        placeholder-gray-400
                        text-gray-700
                        bg-white
                        rounded
                        text-sm
                        shadow
                        focus:outline-none focus:shadow-outline
                        w-full
                      "
                      placeholder="Type a message..."
                    ></textarea>
                    <p text-color="red">
                      120 required character ({{ work.message.length }})
                    </p>
                  </div>
                  <div class="text-center mt-6">
                    <button
                      class="
                        bg-gray-900
                        text-white
                        active:bg-gray-700
                        text-sm
                        font-bold
                        uppercase
                        px-6
                        py-3
                        rounded
                        shadow
                        hover:shadow-lg
                        outline-none
                        focus:outline-none
                        mr-1
                        mb-1
                      "
                      type="button"
                      @click="workWithUs()"
                      style="transition: all 0.15s ease 0s"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer class="relative bg-gray-300 pt-8 pb-6">
      <div
        class="
          bottom-auto
          top-0
          left-0
          right-0
          w-full
          absolute
          pointer-events-none
          overflow-hidden
          -mt-20
        "
        style="height: 80px; transform: translateZ(0px)"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <h4 class="text-3xl font-semibold">Let's keep in touch!</h4>
            <h5 class="text-lg mt-0 mb-2 text-gray-700">
              Find us on any of these platforms, we respond 1-2 business days.
            </h5>
            <div class="mt-6">
              <button
                class="
                  bg-white
                  text-blue-400
                  shadow-lg
                  font-normal
                  h-10
                  w-10
                  items-center
                  justify-center
                  align-center
                  rounded-full
                  outline-none
                  focus:outline-none
                  mr-2
                "
                type="button"
              >
                <i class="fe fe-twitter"></i></button
              ><button
                class="
                  bg-white
                  text-blue-600
                  shadow-lg
                  font-normal
                  h-10
                  w-10
                  items-center
                  justify-center
                  align-center
                  rounded-full
                  outline-none
                  focus:outline-none
                  mr-2
                "
                type="button"
              >
                <i class="fe fe-facebook"></i>
              </button>

              <button
                class="
                  bg-white
                  text-gray-900
                  shadow-lg
                  font-normal
                  h-10
                  w-10
                  items-center
                  justify-center
                  align-center
                  rounded-full
                  outline-none
                  focus:outline-none
                  mr-2
                "
                type="button"
              >
                <i class="fe fe-instagram"></i>
              </button>
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="flex flex-wrap items-top mb-6">
              <div class="w-full lg:w-4/12 px-4 ml-auto">
                <span
                  class="
                    block
                    uppercase
                    text-gray-600 text-sm
                    font-semibold
                    mb-2
                  "
                  >Hayok Insurance</span
                >
                <ul class="list-unstyled">
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="https://hayokmedicare.ng/about.html"
                      >About Us</a
                    >
                  </li>
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="#"
                      >Blog</a
                    >
                  </li>
                </ul>
              </div>
              <div class="w-full lg:w-4/12 px-4">
                <span
                  class="
                    block
                    uppercase
                    text-gray-600 text-sm
                    font-semibold
                    mb-2
                  "
                  >Other Resources</span
                >
                <ul class="list-unstyled">
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="https://hayokmedicare.ng/services.html"
                      >Services</a
                    >
                  </li>
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="#"
                      >Terms &amp; Conditions</a
                    >
                  </li>
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="#"
                      >Privacy Policy</a
                    >
                  </li>
                  <li>
                    <a
                      class="
                        text-gray-700
                        hover:text-gray-900
                        font-semibold
                        block
                        pb-2
                        text-sm
                      "
                      href="https://hayokmedicare.ng/contact.html"
                      >Contact Us</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-6 border-gray-400" />
        <div
          class="flex flex-wrap items-center md:justify-between justify-center"
        >
          <div class="w-full md:w-4/12 px-4 mx-auto text-center">
            <div class="text-sm text-gray-600 font-semibold py-1">
              Copyright © 2022 Powered by
              <a
                target="_blank"
                href="https://www.hayokmedicare.ng"
                class="text-gray-600 hover:text-gray-900"
                >Hayok Medicare</a
              >.
            </div>
          </div>
        </div>
      </div>
      <div class="vld-parent">
        <loading
          :active.sync="isLoading"
          loader="dots"
          :can-cancel="true"
          :is-full-page="fullPage"
        ></loading>
      </div>
    </footer>
  </body>
</template>

<script>
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin

export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,

      lga_states: "",
      work: {
        fullname: "",
        email: "",
        message: "",
      },
    };
  },
  beforeMount() {},
  methods: {
    workWithUs() {
      this.isLoading = true;
      this.axios
        .post("/api/v1/auth/workWithUs", this.work)
        .then((response) => {
          console.log(response);
          this.isLoading = false;
          this.$breadstick.notify(
            "Thank you for your message we would get back to your shortly",
            { position: "top-right" }
          );
          this.work.fullname = "";
          this.work.email = "";
          this.work.message = "";
        })
        .catch((error) => {
          console.log(error.response);
          this.isLoading = false;
          this.$breadstick.notify(
            "Oops! 120 minimum characters needed in message",
            { position: "top-right" }
          );
        });
    },
  },
  created() {
    // this.getStates()
  },
};
</script>

<style  lang="css" scoped>
/* @import "https://cdn.jsdelivr.net/gh/creativetimofficial/tailwind-starter-kit/compiled-tailwind.min.css"; */
.spacer {
  margin-top: 15px;
}
</style>
